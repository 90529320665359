export const config = {
    api_base: 'https://prod.diningtek.com/api/v1',
    api_root : 'https://prod.diningtek.com',
    logo_img_root : '/img/boulderburger.png',
    banner_img_root : '/img/boulderburger-banner.jpg',
    resid : '',
    key_value : 'boulderburger',
    secret_value : 'boulderburger',
    iframe_root : 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3056.059334785143!2d-105.2781483847287!3d40.00712218837295!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876bedf1a8db24bd%3A0x4c1f669689f2b4b8!2sBoulder%20Burger!5e0!3m2!1sen!2sus!4v1650285115969!5m2!1sen!2sus',
    facebook_link:'#',
    RestaurantName:"Boulder Burger"
};
